import React from 'react'

const customContent = (contentType, insurer, incident, inCreation, isMobile, bodyshop) => {

  const rhionCasco = {
    welcome: {
      "over-title-text": null,
      "first-title": null,
      "second-title": "In vier stappen het beste herstelbedrijf voor uw schade",
      "intro-text": "U heeft gekozen voor een aangesloten hersteller uit het rhion netwerk. Wij zorgen ervoor dat u zo snel mogelijk weer met de auto de weg op kunt. Om u zo goed mogelijk van dienst te kunnen zijn, ontvangen wij graag wat informatie.",
      "show-checklist": true,
      "checklist": [{ icon: "icon-check-circle", text: `Geen verhoogd eigen risico` }, { icon: "icon-check-circle", text: `Gratis haal- en brengservice of vervangend vervoer` }, { icon: "icon-check-circle", text: `Altijd inzicht in de voortgang van het herstelproces` }],
    },
    "landing": {
      infoBlock: {
        title: 'Vind een aangesloten hersteller bij u in de buurt',
        'signature-text': 'Powered by',
        'signature-logo': '/img/rhion-logo.svg',
        'intro-text-paragraphs': [
          {
            text:
              'Heeft u schade aan uw auto en wilt u deze zo snel mogelijk herstellen? We helpen u graag verder. Op deze pagina selecteert u eenvoudig een hersteloptie.',
          },
          {
            text: `U heeft een verzekeringspolis van ${insurer.brandName}. U kunt daarom gebruik maken van het rhion herstelnetwerk voor het herstel van uw schade. U profiteert van extra voordelen waaronder gratis vervangend vervoer en een gratis haal- en brengservice.`,
          },
          {
            text:
              <React.Fragment> Klik op "maak een herstelafspraak" om een aangesloten hersteller uit ons netwerk te kiezen.`</React.Fragment>,
          },
          {
            text: <React.Fragment>Maakt u geen gebruik van een aangesloten hersteller? Dan ontvangen wij graag de offerte van uw eigen hersteller. U profiteert niet van de extra voordelen. Klik op "offerte aanleveren" als u kiest voor een eigen hersteller.`</React.Fragment>,
          },
        ],
      },
      formBlock: {
        'card-one': {
          button: 'Maak een herstelafspraak',
          title: 'Aangesloten herstelnetwerk',
          subtitle: null,
          paragraphs: [
            {
              text:
                'Wij werken samen met de beste herstelbedrijven van Nederland. U profiteert altijd van de volgende voordelen:',
            },
          ],
          list: [
            {
              text: 'Gratis haal- en brengservice of vervangend vervoer.',
              icon: true,
            },
            {
              text:
                'U hoeft niets voor te schieten, wij regelen alles met het herstelbedrijf.',
              icon: true,
            },
            {
              text: 'Zeven jaar garantie op het uitgevoerde herstel.',
              icon: true,
            },
            {
              text:
                'U betaalt geen, of een verlaagd, eigen risico (dit hangt af van uw polisvoorwaarden).',
              icon: true,
            },
          ],
          'closing-paragraphs': [
            {
              text:
                'Klik op de onderstaande knop om de beschrijving en foto’s van uw schade toe te voegen. Het meest geschikte herstelbedrijf wordt aan u voorgesteld en neemt vervolgens binnen één werkdag contact met u op om een afspraak in te plannen.',
            },
          ],
        },
        'card-two': {
          button: 'Offerte aanleveren',
          title: 'Uw eigen hersteller',
          subtitle: null,
          paragraphs: [
            {
              text:
                'Maakt u geen gebruik van een aangesloten hersteller? Dan ontvangen wij graag foto’s van uw schade en de offerte van uw eigen hersteller zodat wij deze kunnen beoordelen. U kunt de informatie via onderstaande knop aanleveren.',
            },
            {
              text:
                'Wij nemen uw schade  zo snel mogelijk in behandeling. Houd rekening met het volgende:',
            },
          ],
          list: [
            {
              text:
                'U levert een duidelijke offerte of calculatie van de schade.',
            },
            { text: 'U moet het schadebedrag zelf voorschieten.' },
            {
              text:
                'Het kan zijn dat u een verhoogd eigen risico van € 500,- heeft, kijk goed naar uw aanvullende dekking en voorwaarden hiervoor.',
            },
          ],
          'closing-paragraphs': null,
        },
      },
    },
    "personal_start": {
      "text": isMobile ? 'Hieronder ziet u de stappen om uw schade volledig te melden.' : 'Hiernaast ziet u de stappen om uw schade volledig te melden.',
      "second-text": "Heeft u alle informatie toegevoegd? Dan stellen wij het meest geschikte herstelbedrijf aan u voor.",
      "third-text": "Het herstelbedrijf neemt binnen één werkdag contact met u op om een afspraak in te plannen en komt naar u toe om uw auto op te halen.",
      "show-timeline": true,
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "star", text: "U wordt direct gekoppeld aan een herstelbedrijf." }]
    },
    gender: {
      "title": 'Hoe wilt u graag aangesproken worden?',
    },
    "damage_start": {
      "title": "Bedankt voor uw persoonlijke gegevens",
      "text": "Klik op “Volgende” om uw schade in een paar stappen te beschrijven.",
      "show-timeline": true,
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "star", text: "U wordt direct gekoppeld aan een herstelbedrijf." }]
    },
    "uploads_start": {
      "title": "Bedankt voor het beschrijven van uw schade.",
      "text": "Klik op “Volgende” om vijf foto’s van de schade te uploaden.",
      "show-timeline": true,
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "star", text: "U wordt direct gekoppeld aan een herstelbedrijf." }]
    },
    "images": {
      "intro-text": "Het herstelbedrijf heeft minimaal vijf foto’s van uw schade nodig; van afstand, van dichtbij, onder een schuine hoek en van het dashboard. De instructies voor het maken van de foto vindt u hieronder. Let op een goede belichting!",
      "second-text": "Klik op de tekst voor uitleg.",
    },
    "recovery_start": {
      "title": "U heeft alle informatie toegevoegd",
      "text": "Klik op “Volgende” om uw dossier af te ronden. We stellen het herstelbedrijf meteen aan u voor.",
      "show-timeline": true,
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "star", text: "U wordt direct gekoppeld aan een herstelbedrijf." }]
    },
    success: {
      "show-left-logo": false,
      "company-middle-logo": true,
      "title": bodyshop ? `We hebben ${bodyshop.name || bodyshop.companyName} geselecteerd om de schade aan uw auto te herstellen` : "Beste klant",
      "show-bodyshop-block": true,
      "bodyshop-block-title": "Informatie",
      "text": `${bodyshop ? bodyshop.name || bodyshop.companyName : "Uw hersteller"} neemt binnen één werkdag contact met u op om een afspraak te maken voor het herstel. Hiernaast vindt u een overzicht van de vervolgstappen van het totale herstelproces.`,
      "show-timeline": true,
      "timeline-title": "Volg het herstelproces stap voor stap",
      "timeline": [{ icon: "questionmark", text: "Schade beschrijven." }, { icon: "user", text: `${bodyshop && bodyshop.contactPersons && bodyshop.contactPersons.length ? bodyshop.contactPersons[0].name : bodyshop ? bodyshop.name || bodyshop.companyName : "Uw hersteller"} neemt binnen één werkdag contact met u op om een afspraak te maken voor het herstel.`, active: true, imgIcon: true }, { icon: "towtruck", text: "Uw auto wordt gratis opgehaald." }, { icon: "wrench", text: `${bodyshop ? bodyshop.name || bodyshop.companyName : "uw hersteller"} herstelt uw auto.` }, { icon: "car-returned", text: "Uw auto wordt gratis bij u teruggebracht." }],
      "feedback-timeline": [{ icon: "questionmark", text: "Schade beschrijven." }, { icon: "user", text: `U word zo spoedig mogelijk gebeld door ${bodyshop ? bodyshop.name || bodyshop.companyName : "uw hersteller"}`, active: true }, { icon: "towtruck", text: "Uw auto wordt gratis opgehaald." }, { icon: "wrench", text: `${bodyshop ? bodyshop.name || bodyshop.companyName : "uw hersteller"} herstelt uw auto.` }, { icon: "car-returned", text: "Uw auto wordt gratis bij u teruggebracht." }],
      "failed-timeline": null,
    },
  }

  const VIP = {
    welcome: {
      "over-title-text": "Welkom bij de",
      "first-title": "VIP schadeservice",
      "second-title": null,
      "intro-text": "Wij zorgen ervoor dat u weer zo snel en makkelijk mogelijk met de auto de weg op kunt.",
      "show-checklist": true,
      "checklist": [{ icon: "icon-check-circle", text: `Kosteloos aangeboden door ${insurer.brandName || 'uw verzekeraar'}` }, { icon: "icon-check-circle", text: `Altijd inzicht in de voortgang van het proces` }, { icon: "icon-check-circle", text: `Gratis haal & breng service, vervangend vervoer en geen verhoogd eigen risico` }],
    },
    "personal_start": {
      "text": "In vier stappen het beste herstelbedrijf voor uw schade.",
      "second-text": null,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: inCreation ? 'Vul uw persoonlijke gegevens in zodat we u kunnen bereiken.' : 'Controleer uw persoonlijke gegevens zodat we u kunnen bereiken.' }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Door fotos en aanvullende documenten te leveren kunnen wij veel sneller aan de slag met uw schade." }, { icon: "star", text: "U wordt direct gekoppeld aan uw VIP Schadeservice herstelbedrijf." }]
    },
    "damage_start": {
      "title": "Bedankt voor uw gegevens.",
      "text": null,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: inCreation ? 'Vul uw persoonlijke gegevens in zodat we u kunnen bereiken.' : 'Controleer uw persoonlijke gegevens zodat we u kunnen bereiken.' }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Door fotos en aanvullende documenten te leveren kunnen wij veel sneller aan de slag met uw schade." }, { icon: "star", text: "U wordt direct gekoppeld aan uw VIP Schadeservice herstelbedrijf." }]
    },
    "uploads_start": {
      "title": "Bedankt voor het beschrijven van uw schade.",
      "text": "Klik op “Volgende” om vijf foto’s van de schade te uploaden.",
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: inCreation ? 'Vul uw persoonlijke gegevens in zodat we u kunnen bereiken.' : 'Controleer uw persoonlijke gegevens zodat we u kunnen bereiken.' }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Door fotos en aanvullende documenten te leveren kunnen wij veel sneller aan de slag met uw schade." }, { icon: "star", text: "U wordt direct gekoppeld aan uw VIP Schadeservice herstelbedrijf." }]
    },
    "images": {
      "intro-text": "Het herstelbedrijf heeft minimaal vijf foto’s van uw schade nodig; van afstand, van dichtbij, onder een schuine hoek en van het dashboard. De instructies voor het maken van de foto vindt u hieronder. Let op een goede belichting!",
      "second-text": "Zie de instructies hieronder. Klik op de tekst voor uitleg",
    },
    "recovery_start": {
      "title": "Vind mijn herstelbedrijf",
      "text": null,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: inCreation ? 'Vul uw persoonlijke gegevens in zodat we u kunnen bereiken.' : 'Controleer uw persoonlijke gegevens zodat we u kunnen bereiken.' }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Door fotos en aanvullende documenten te leveren kunnen wij veel sneller aan de slag met uw schade." }, { icon: "star", text: "U wordt direct gekoppeld aan uw VIP Schadeservice herstelbedrijf." }]
    },
    success: {
      "show-faq": true,
      "show-left-logo": isMobile ? false : true,
      "company-middle-logo": isMobile ? true : false,
      "show-rating": true,
      "title": `${bodyshop ? bodyshop.name || bodyshop.companyName : ""}`,
      "show-info-block": true,
      "info-block-title": "Uw geselecteerde VIP herstelbedrijf",
      "info-block-text": bodyshop && bodyshop.info ? `${bodyshop.info}` : null,
      "text": `${isMobile ? 'Hieronder' : 'Hiernaast'} vindt u een overzicht van de vervolgstappen om het gehele proces snel en professioneel af te ronden.`,
      "show-timeline": true,
      "timeline-title": null,
      "timeline-show-check": true,
      "timeline": [{ icon: "mail", text: "Schade melden en dossier compleet maken." }, { icon: "user", text: `${bodyshop && bodyshop.contactPersons && bodyshop.contactPersons.length ? bodyshop.contactPersons[0].name : bodyshop ? bodyshop.name || bodyshop.companyName : "Uw hersteller"} neemt binnen één werkdag contact met u op om een afspraak te maken voor het herstel.`, active: true, imgIcon: true }, { icon: "towtruck", text: "Uw auto wordt gratis opgehaald." }, { icon: "wrench", text: `${bodyshop ? bodyshop.name || bodyshop.companyName : "uw hersteller"} herstelt uw auto.` }, { icon: "car-returned", text: "Uw auto wordt ook gratis weer bij u teruggebracht." }],
      "feedback-timeline": [{ icon: "mail", text: "Schade melden en dossier compleet maken." }, { icon: "user", text: `U word zo spoedig mogelijk gebeld door ${bodyshop ? bodyshop.name || bodyshop.companyName : "uw hersteller"}`, active: true }, { icon: "towtruck", text: "Uw auto wordt gratis opgehaald." }, { icon: "wrench", text: `${bodyshop ? bodyshop.name || bodyshop.companyName : "uw hersteller"} herstelt uw auto.` }, { icon: "car-returned", text: "Uw auto wordt gratis bij u teruggebracht." }],
      "failed-timeline": null,
      "spcase-failed-timeline": [{ icon: "mail", text: "Schade melden en dossier compleet maken." }, { icon: "user", text: "Wij nemen binnen één werkdag contact met u op." }],
    },
  }

  const EM = {
    welcome: {
      "over-title-text": null,
      "first-title": null,
      "second-title": "Uw eigen herstelbedrijf",
      "intro-text": "Wilt u geen gebruik van het rhion herstelnetwerk, maar kiest u liever zelf een hersteller? Stuur ons in dat geval de offerte en een beschrijving van uw schade zodat wij deze kunnen beoordelen.",
      "show-checklist": true,
      "checklist": [{ icon: "minus", text: `U levert een duidelijke offerte of calculatie van de schade.` }, { icon: "minus", text: `U krijgt geen garantie van rhion op uw reparatie.` }, { icon: "minus", text: `U moet het schadebedrag zelf voorschieten.` }, { icon: "minus", text: `Het kan zijn dat u een verhoogd eigen risico van € 500,- heeft, kijk goed naar uw aanvullende dekking en voorwaarden hiervoor.` }],
    },
    "landing": {
      infoBlock: {
        title: 'Vind een aangesloten hersteller bij u in de buurt',
        'signature-text': 'Powered by',
        'signature-logo': '/img/rhion-logo.svg',
        'intro-text-paragraphs': [
          {
            text:
              'Heeft u schade aan uw auto en wilt u deze zo snel mogelijk herstellen? We helpen u graag verder. Op deze pagina selecteert u eenvoudig een hersteloptie.',
          },
          {
            text: `U heeft een verzekeringspolis van ${insurer.brandName}. U kunt daarom gebruik maken van het rhion herstelnetwerk voor het herstel van uw schade. U profiteert van extra voordelen waaronder gratis vervangend vervoer en een gratis haal- en brengservice.`,
          },
          {
            text:
              <React.Fragment> Klik op "maak een herstelafspraak" om een aangesloten hersteller uit ons netwerk te kiezen.`</React.Fragment>,
          },
          {
            text: <React.Fragment>Maakt u geen gebruik van een aangesloten hersteller? Dan ontvangen wij graag de offerte van uw eigen hersteller. U profiteert niet van de extra voordelen. Klik op "offerte aanleveren" als u kiest voor een eigen hersteller.`</React.Fragment>,
          },
        ],
      },
      formBlock: {
        'card-one': {
          button: 'Maak een herstelafspraak',
          title: 'Aangesloten herstelnetwerk',
          subtitle: null,
          paragraphs: [
            {
              text:
                'Wij werken samen met de beste herstelbedrijven van Nederland. U profiteert altijd van de volgende voordelen:',
            },
          ],
          list: [
            {
              text: 'Gratis haal- en brengservice of vervangend vervoer.',
              icon: true,
            },
            {
              text:
                'U hoeft niets voor te schieten, wij regelen alles met het herstelbedrijf.',
              icon: true,
            },
            {
              text: 'Zeven jaar garantie op het uitgevoerde herstel.',
              icon: true,
            },
            {
              text:
                'U betaalt geen, of een verlaagd, eigen risico (dit hangt af van uw polisvoorwaarden).',
              icon: true,
            },
          ],
          'closing-paragraphs': [
            {
              text:
                'Klik op de onderstaande knop om de beschrijving en foto’s van uw schade toe te voegen. Het meest geschikte herstelbedrijf wordt aan u voorgesteld en neemt vervolgens binnen één werkdag contact met u op om een afspraak in te plannen.',
            },
          ],
        },
        'card-two': {
          button: 'Offerte aanleveren',
          title: 'Uw eigen hersteller',
          subtitle: null,
          paragraphs: [
            {
              text:
                'Maakt u geen gebruik van een aangesloten hersteller? Dan ontvangen wij graag foto’s van uw schade en de offerte van uw eigen hersteller zodat wij deze kunnen beoordelen. U kunt de informatie via onderstaande knop aanleveren.',
            },
            {
              text:
                'Wij nemen uw schade  zo snel mogelijk in behandeling. Houd rekening met het volgende:',
            },
          ],
          list: [
            {
              text:
                'U levert een duidelijke offerte of calculatie van de schade.',
            },
            { text: 'U moet het schadebedrag zelf voorschieten.' },
            {
              text:
                'Het kan zijn dat u een verhoogd eigen risico van € 500,- heeft, kijk goed naar uw aanvullende dekking en voorwaarden hiervoor.',
            },
          ],
          'closing-paragraphs': null,
        },
      },
    },
    "personal_start": {
      "text": isMobile ? 'Hieronder ziet u de stappen om uw schade volledig te melden.' : 'Hiernaast ziet u de stappen om uw schade volledig te melden.',
      "second-text": "Heeft u alle informatie toegevoegd? Dan nemen wij uw dossier zo snel mogelijk in behandeling.",
      "show-timeline": true,
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "invoice", text: "Voeg de offerte en contactgegevens van uw herstelbedrijf toe." }]
    },
    gender: {
      "title": 'Hoe wilt u graag aangesproken worden?',
    },
    "damage_start": {
      "title": "Bedankt voor uw persoonlijke gegevens",
      "text": "Klik op “Volgende” om uw schade in een paar stappen te beschrijven.",
      "show-timeline": true,
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "invoice", text: "Voeg de offerte en contactgegevens van uw herstelbedrijf toe." }]
    },
    "uploads_start": {
      "title": "Bedankt voor het beschrijven van uw schade.",
      "text": "Klik op “Volgende” om vijf foto’s van de schade te uploaden. U kunt optioneel documenten zoals het schadeaangifteformulier toevoegen.",
      "show-timeline": true,
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "invoice", text: "Voeg de offerte en contactgegevens van uw herstelbedrijf toe." }]
    },
    "images": {
      "intro-text": "Wij hebben minimaal vijf foto’s van uw schade nodig; van afstand, van dichtbij, onder een schuine hoek en van het dashboard. De instructies voor het maken van de foto vindt u hieronder. Let op een goede belichting!",
      "second-text": "Klik op de tekst voor uitleg.",
    },
    "ownbodyshop_start": {
      "title": "Bedankt voor het uploaden van de foto’s.",
      "text": "Klik op “Volgende” om de offerte van uw herstelbedrijf toe te voegen. De contactgegevens het herstelbedrijf kunt u ook invullen.",
      "show-timeline": true,
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "invoice", text: "Voeg de offerte en contactgegevens van uw herstelbedrijf toe." }]
    },
    "recovery_start": {
      "title": "U heeft alle informatie toegevoegd",
      "text": null,
      "show-timeline": true,
      "show-extra-block": true,
      "extra-block-title": "Kiest u toch liever voor een aangesloten herstelbedrijf in plaats van uw eigen hersteller?",
      "extra-block-auxiliar-text": 'Dat kan nog steeds. Klik op onderstaande knop om uw keuze te wijzigen. Het geselecteerde herstelbedrijf neemt binnen één werkdag contact met u op om een afspraak in te plannen.',
      "extra-button-text": 'Ik kies voor een aangesloten hersteller',
      "show-checklist": true,
      "checklist": [{ icon: "icon-check-circle", text: `Gratis haal- en brengservice óf vervangend vervoer.` }, { icon: "icon-check-circle", text: `U hoeft niets voor te schieten, wij regelen alles met het herstelbedrijf.` }, { icon: "icon-check-circle", text: `Zeven jaar garantie op het uitgevoerde herstel.` }, { icon: "icon-check-circle", text: `U betaalt geen, of een verlaagd, eigen risico (dit hangt af van uw polisvoorwaarden).` }],
      "checklist-title": "Daarnaast profiteert u van de volgende voordelen:",
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "invoice", text: "Voeg de offerte en contactgegevens van uw herstelbedrijf toe." }]
    },
    success: {
      "show-left-logo": false,
      "company-middle-logo": true,
      "title": `U heeft alle informatie toegevoegd`,
      "text": `Bedankt voor het beschrijven van uw schade. Wij nemen uw schadedossier meteen in behandeling en houden u per e-mail op de hoogte over het verdere verloop van ons proces.`,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Uw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "5 Foto's van de schade uploaden." }, { icon: "invoice", text: "Een offerte en contactgegevens van uw hersteller toevoegen." }, { icon: "mail", text: "Wij houden u per e-mail op de hoogte over het verdere verloop van het proces.", active: true }],
      "feedback-timeline": [{ icon: "user", text: "Uw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "5 Foto's van de schade uploaden." }, { icon: "invoice", text: "Een offerte en contactgegevens van uw hersteller toevoegen." }, { icon: "mail", text: "Wij houden u per e-mail op de hoogte over het verdere verloop van het proces.", active: true }],
      "failed-timeline": [{ icon: "user", text: "Uw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "5 Foto's van de schade uploaden." }, { icon: "invoice", text: "Een offerte en contactgegevens van uw hersteller toevoegen." }, { icon: "mail", text: "Wij houden u per e-mail op de hoogte over het verdere verloop van het proces.", active: true }],
    },
  }

  const DI = {
    welcome: {},
    "personal_start": {
      "text": "In 4 stappen uw schade volledig online beschreven",
      "second-text": null,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: inCreation ? 'Vul uw persoonlijke gegevens in zodat we u kunnen bereiken.' : 'Controleer uw persoonlijke gegevens zodat we u kunnen bereiken.' }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Door fotos en aanvullende documenten te leveren kunnen wij veel sneller aan de slag met uw schade." }, { icon: "star", text: "'Wij bellen u voor het maken van een herstelafspraak." }]
    },
    "license": {
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze digitale intake is dat we aan de hand van uw adres kunnen bepalen wat de dichtstbijzijnde vestiging is.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto.'
        }]
    },
    "name": {
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze digitale intake is dat we aan de hand van uw adres kunnen bepalen wat de dichtstbijzijnde vestiging is.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto.'
        }]
    },
    "contact": {
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn contactgegevens nodig?',
          answer: 'Onderdeel van deze digitale intake is dat we aan de hand van uw adres kunnen bepalen wat de dichtstbijzijnde vestiging is.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto.'
        }]
    },
    "postal": {
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze digitale intake is dat we aan de hand van uw adres kunnen bepalen wat de dichtstbijzijnde vestiging is.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto.'
        }]
    },
    "address": {
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze digitale intake is dat we aan de hand van uw adres kunnen bepalen wat de dichtstbijzijnde vestiging is.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto.'
        }]
    },
    "damage_start": {
      "title": "Bedankt voor uw gegevens.",
      "text": null,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: inCreation ? 'Vul uw persoonlijke gegevens in zodat we u kunnen bereiken.' : 'Controleer uw persoonlijke gegevens zodat we u kunnen bereiken.' }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Door fotos en aanvullende documenten te leveren kunnen wij veel sneller aan de slag met uw schade." }, { icon: "star", text: "'Wij bellen u voor het maken van een herstelafspraak." }]
    },
    "damage_cause_category": {

      "faqs": [
        {
          question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
          answer: 'Deze informatie helpt ons bij het bepalen van het schadebeeld en het inplannen van een reparatie.'
        },
        {
          question: 'Wat betekenen de verschillende oorzaken precies?',
          answer: (<React.Fragment>
            <dl>
              <dt>Aanrijding met voertuig - </dt>
              <dd>
                U heeft een aanrijding gehad met een ander gemotoriseerd voertuig, dit
                kan bijvoorbeeld een auto, busje, vrachtwagen of scooter zijn.
              </dd>
              <br />
              <br />
              <dt>Aanrijding met voetganger/fiets - </dt>
              <dd>
                U heeft een aanrijding gehad met een niet-gemotoriseerd voertuig zoals
                een fiets of een voetganger.
              </dd>
              <br />
              <br />
              <dt>Aanrijding met dier - </dt>
              <dd>
                U heeft een aanrijding gehad met een dier op de weg, bijvoorbeeld een
                hond, hert of gans.
              </dd>
              <br />
              <br />
              <dt>Aanrijding met (vast) object - </dt>
              <dd>
                U heeft bijvoorbeeld een aanrijding gehad met een hekje, een vangrail
                of een bord langs de weg.
              </dd>{' '}
              <br />
              <br />
              <dt>Inbraak - </dt>
              <dd>
                Er is in uw auto ingebroken en er zijn eigendommen van u uit de auto
                gestolen of er zijn onderdelen van uw auto uit uw auto gestolen.
              </dd>{' '}
              <br />
              <br />
              <dt>Vandalisme - </dt>
              <dd>Uw auto is (opzettelijk) beschadigd aan binnen- of buitenkant.</dd>
              <br />
              <br />
              <dt>Hagel/storm - </dt>
              <dd>
                Uw auto heeft schade opgelopen als gevolg van een hagelbui of een
                storm.
              </dd>
              <br />
              <br />
              <dt>Brand - </dt>
              <dd>
                Uw auto heeft schade opgelopen als gevolg van een brand in of in de
                buurt van uw auto.
              </dd>
            </dl>
          </React.Fragment>
          ),
        },
      ]
    },
    "speed": {
      "faqs": [
        {
          question: 'Waarom is het belangrijk om mijn snelheid te weten?',
          answer: 'Het is belangrijk voor ons om te weten hoe hard u ongeveer reed tijdens het incident. Op deze manier kunnen wij de impact van uw schade beter inschatten.'
        },
      ]
    },
    "exterior": {
      "faqs": [
        {
          question: 'Wat als mijn auto op meerdere plekken beschadigd is?',
          answer: 'U kunt meerdere vakken aangeven waar uw auto beschadigd is.',
        },
        {
          question: 'Ik kan de plek waar mijn auto is beschadigd niet aangeven, wat moet ik nu doen?',
          answer: 'Arceer het vlak dat het meest in de buurt is van uw schade. U kunt dit later telefonisch toelichten wanneer wij u bellen om een afspraak te maken voor het herstel.'
        },
      ]
    },
    "uploads_start": {
      "title": "Bedankt voor het beschrijven van uw schade.",
      "text": null,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: inCreation ? 'Vul uw persoonlijke gegevens in zodat we u kunnen bereiken.' : 'Controleer uw persoonlijke gegevens zodat we u kunnen bereiken.' }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Door fotos en aanvullende documenten te leveren kunnen wij veel sneller aan de slag met uw schade." }, { icon: "star", text: "'Wij bellen u voor het maken van een herstelafspraak." }]
    },
    "images": {
      "intro-text": "Wij hebben minimaal 5 foto’s van uw schade nodig: van een afstandje, dichtbij, onder een schuine hoek en van het dashboard. Let op een goede belichting!",
      "second-text": "Zie de instructies hieronder. Klik op de tekst voor uitleg",
      "faqs": [
        {
          question: 'Waarom hebben jullie foto’s van mijn schade nodig?',
          answer: 'Op basis van de foto’s kunnen wij een inschatting maken hoe groot de schade is en hoe lang wij nodig zullen hebben voor het herstel.'
        },
      ]
    },
    success: {
      "show-left-logo": isMobile ? false : true,
      "company-middle-logo": isMobile ? true : false,
      "title": `${bodyshop ? bodyshop.name || bodyshop.companyName : ""}`,
      "show-info-block": true,
      "info-block-title": "Wij nemen zo spoedig mogelijk contact met u op voor het maken van een herstel afspraak",
      "info-block-text": null,
      "text": `${isMobile ? 'Hieronder' : 'Hiernaast'} vindt u een overzicht van de vervolgstappen om het gehele proces snel en professioneel af te ronden.`,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Controleer uw persoonlijke gegevens zodat we u kunnen bereiken." }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij bellen u voor het maken van een herstelafspraak.", active: true }],
      "feedback-timeline": [{ icon: "user", text: "Controleer uw persoonlijke gegevens zodat we u kunnen bereiken." }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij bellen u voor het maken van een herstelafspraak.", active: true }],
      "failed-timeline": [{ icon: "user", text: "Controleer uw persoonlijke gegevens zodat we u kunnen bereiken." }, { icon: "questionmark", text: "Beantwoord vier vragen over uw schade zodat we deze goed in kaart kunnen brengen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij bellen u voor het maken van een herstelafspraak.", active: true }],
    },
  }

  const schadegarant = {
    welcome: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    "personal_start": {
      "use-custom-logo": true,
      "title": 'Uw eXperience begint!',
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
      "text": 'Hartelijk bedankt voor het melden van uw schade.',
      "second-text": 'In vier stappen maakt u nu uw eigen dossier aan. Als alle informatie compleet is, neemt het herstelbedrijf contact op om een afspraak te maken.',
      "third-text": 'Klik op ‘Volgende’ en voeg uw gegevens toe.',
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }]
    },
    license: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "title": 'Hebben wij uw kenteken en schadedatum goed ontvangen?',
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    name: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    contact: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    postal: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    address: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    gender: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "title": 'Hoe wilt u graag aangesproken worden?',
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    "damage_start": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
      "text": "Klik op ‘Volgende’ en volg de stappen om uw schade te beschrijven.",
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }]
    },
    "damage_cause_category": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    "damage_cause_dropdown": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    "damage_cause_combined_dropdown": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    speed: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    interior: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    exterior: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    "damage_type": {
      infoBlock: {
        title: `Welkom in eXperience!`,
        'intro-text-paragraphs': [
          {
            text:
              'In een paar stappen meldt u hier uw schade en krijgt u een persoonlijk eXperience dossier. Via de track-and-trace functie volgt u de status van uw schade.',
          },
          {
            text:
              'Heeft u al een afspraak bij een bedrijf uit ons schadeherstelnetwerk gemaakt? Dan is melden via eXperience niet meer nodig. Het herstelbedrijf verzorgt uw eXperience dossier.',
          },
          {
            text:
              'Foto’s helpen het herstelbedrijf bij de beoordeling van uw schade. Met een tablet of smartphone maakt en selecteert u ze hier direct. Via een desktop dient u ze eerst zelf naar uw pc te uploaden (max.10 stuks).',
          },
          {
            text:
              'Om uw eXperience dossier te bekijken logt u in met uw e-mailadres en het kenteken van uw auto.',
          },
          {
            text: <React.Fragment>Meer informatie over eXperience vindt u op de <a href='https://www.schadegarant.nl/experience' target='blank'><u>eXperience pagina</u></a>. In ons <a href='https://www.schadegarant.nl/privacystatement/' target='blank'><u>privacystatement</u></a> kunt u meer lezen over hoe wij veilig omgaan met vertrouwelijke gegevens.</React.Fragment>,
          },
        ],
      },
      formBlock: {
        'card-one': {
          button: 'Volgende',
          title:
            'Selecteer welke soort schade uw auto heeft en klik op “Volgende”',
          subtitle: null,
          paragraphs: null,
          list: null,
          'closing-paragraphs': null,
        },
        'card-two': {
          button: 'eXperience dossier aanmaken',
          title: 'Vul hier uw gegevens in',
          subtitle: null,
          paragraphs: null,
          list: null,
          'closing-paragraphs': null,
        },
      },
    },
    "uploads_start": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "title": "Bedankt voor het beschrijven van uw schade",
      "text": 'Klik op ‘Volgende’ om foto’s (max. 10 stuks) van uw schade toe te voegen.',
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }]
    },
    "images": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "max-img-size": 40,
      "max-number-images": 10,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    "ownbodyshop_start": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "title": "Bedankt voor het toevoegen van uw foto’s",
      "title-no-image": "Klik op ‘Volgende’ om een herstelbedrijf te kiezen.",
      "text": 'Klik op ‘Volgende’ om een herstelbedrijf te kiezen.',
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }]

    },
    "bodyshop_select": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
      "over-title-text": 'Hersteller kiezen',
      "title": 'Kies een herstelbedrijf',
    },
    "bodyshop_confirm": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
      "over-title-text": 'Hersteller kiezen',
    },
    "recovery_start": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    "driveable_damage": {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
    },
    success: {
      "use-custom-logo": true,
      "custom-help-link": true,
      "help-link-text": "Staat uw vraag hier niet tussen? Klik hier",
      "timeline-show-check": true,
      "title": bodyshop ? `${bodyshop.name || bodyshop.companyName} neemt contact op` : `Beste klant,`,
      "text": `Uw eXperience dossier is compleet. Wij hebben het dossier gedeeld met ${bodyshop ? bodyshop.name || bodyshop.companyName : 'uw herstelbedrijf'}. Het bedrijf gaat uw schade beoordelen en neemt binnen een werkdag contact met u op om een afspraak in te plannen.`,
      "second-text": 'Wij houden u per e-mail op de hoogte. Met de track-and-trace functie kunt u uw dossier volgen. Gebruik uw e-mailadres en het kenteken van uw auto om in te loggen.',
      "second-text-no-track-trace": 'Wij houden u per e-mail op de hoogte. Gebruik uw e-mailadres en het kenteken van uw auto om in te loggen.',
      "third-text": <React.Fragment>Meer informatie over eXperience en de veelgestelde vragen vindt u op de <a href='https://www.schadegarant.nl/experience' target='blank'><u>eXperience pagina</u></a>.</React.Fragment>,
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }, { icon: "phone", text: "Nog even geduld. We zijn de gegevens aan het controleren. We sturen binnen enkele minuten een e-mail", active: true }],
      "show-background": false,
    },
    "support": {
      "use-custom-logo": true,
      "over-title-text": "support",
      "title": "Komt u er niet uit?",
      "subtitle": null,
    },
  }

  const dealerSaas = {
    "personal_start": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "title": inCreation ? "Beste klant," : incident && incident.customerFirstName ? `Beste ${incident.customerFirstName} ${incident.customerLastName},` : "Beste klant,",
      "text": 'Hiernaast ziet u de stappen om uw schade volledig te melden.',
      "second-text": "Heeft u alle informatie toegevoegd? Dan stellen wij het meest geschikte herstelbedrijf aan u voor.",
      "third-text": null,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "star", text: "U wordt direct gekoppeld aan een herstelbedrijf." }]
    },
    'saf_required': {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Schadeformulier",
      "intro-text": "Heeft u een ingevuld schadeformulier? Voeg deze dan toe.",
      "second-text": "Klik op “Volgende” als u geen schadeformulier wilt toevoegen.",
    },
    "thank_you": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "title": incident && bodyshop ? `We hebben dealer ${bodyshop.name || bodyshop.companyName} geselecteerd om de schade aan uw auto te herstellen` : "Beste klant",
      "text": bodyshop ? `Dealer ${bodyshop.name || bodyshop.companyName} neemt contact met u op om een afspraak te maken voor het herstel. Hiernaast vindt u een overzicht van de vervolgstappen van het totale herstelproces.` : "klik op 'Hersteller kiezen', selecteer een hersteller uit ons netwerk en bevestig je keuze om je schademelding af te ronden.",
      "feedback-text": "We hebben de fotos in goede orde ontvangen en zullen binnen 1 werkdag contact met u opnemen. Bedankt",
      "end-text": "U kunt deze pagina sluiten.",
      "link": `https://www.carmeleon.info/schadeherstellers?LicensePlate=${incident?.licensePlate}`,
      "show-bodyshop-block": true,
      "bodyshop-block-title": "Informatie",
      "show-left-logo": false,
      "company-middle-logo": true,
      "glass-title": incident && incident.customerFirstName ? `Beste ${incident.customerFirstName} ${incident.customerLastName},` : "Beste klant,",
      "glass-text": incident && bodyshop ? `Uw schademelding is in goede orde ontvangen. Neem contact op met ${bodyshop.name || bodyshop.companyName}, voor het maken van een herstelafspraak.` : `Uw schademelding is in goede orde ontvangen. Neem contact op met uw dealer, voor het maken van een herstelafspraak.`,
      "glass-second-text": incident && bodyshop ? `${bodyshop.name || bodyshop.companyName} heeft ondertussen ook alle relevante gegevens over uw schademelding ontvangen.` : 'Uw dealer heeft ondertussen ook alle relevante gegevens over uw schademelding ontvangen.',
      "show-timeline": true,
      "timeline-title": null,
      "timeline-glass": [{ icon: "questionmark", text: "Schade beschrijven" }, { icon: "user", text: bodyshop ? `Neem contact op met ${bodyshop.name || bodyshop.companyName} voor het inplannen van een afspraak` : "Kies een hersteller", active: true, imgIcon: true }, { icon: "wrench", text: "De schade aan uw auto wordt hersteld" }],
      "timeline": [{ icon: "questionmark", text: "Schade beschrijven" }, { icon: "user", text: bodyshop ? `Dealer ${bodyshop.name || bodyshop.companyName} neemt contact met u op om een afspraak te maken voor het herstel` : "Kies een hersteller", active: true, imgIcon: true }, { icon: "towtruck", text: "Uw auto gaat naar de hersteller." }, { icon: "wrench", text: bodyshop ? `Dealer ${bodyshop.name || bodyshop.companyName} herstelt uw auto.` : "Uw hersteller herstelt uw auto" }, { icon: "car-returned", text: "U krijgt uw auto terug." }]
    }
  }

  const intake = {
    "personal_start": {
      "text": 'Hiernaast zie je de stappen om jouw schade volledig te melden.',
      "second-text": "Heb je alle informatie toegevoegd? Dan stellen wij het meest geschikte herstelbedrijf aan je voor.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    license: {
      "title": !inCreation ? 'Controleer je schadedatum' : 'Vul je kenteken en de schadedatum in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van jouw adres kunnen bepalen wat het beste herstelbedrijf in jouw regio is om je schade te herstellen. Zij zullen jouw auto ook bij je op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    name: {
      "title": !inCreation ? 'Controleer je naam' : 'Vul je naam in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van jouw adres kunnen bepalen wat het beste herstelbedrijf in jouw regio is om je schade te herstellen. Zij zullen jouw auto ook bij je op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    contact: {
      "title": isMobile ? 'Vul je email en telefoon-nummer in' : 'Vul je email en telefoonnummer in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn contactgegevens nodig?',
          answer: 'Als we jouw telefoonnummer hebben, kunnen wij dit delen met het herstelbedrijf dat jouw auto zal herstellen. Zij zullen je bellen om een afspraak te maken wanneer ze jouw auto kunnen ophalen. Met jouw e-mailadres kunnen we je op de hoogte houden van de voortgang van het herstel van jouw auto.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    postal: {
      "title": !inCreation ? 'Controleer je postcode en huisnummer' : 'Vul je postcode en huisnummer',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van jouw adres kunnen bepalen wat het beste herstelbedrijf in jouw regio is om je schade te herstellen. Zij zullen jouw auto ook bij je op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    address: {
      "title": 'Controleer je adres',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van jouw adres kunnen bepalen wat het beste herstelbedrijf in jouw regio is om je schade te herstellen. Zij zullen jouw auto ook bij je op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    gender: {
      "title": 'Hoe wil je aangesproken worden',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "damage_start": {
      "title": "Bedankt voor je persoonlijke gegevens",
      "text": "Klik op “Volgende” om je schade in een paar stappen te beschrijven.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    "damage_cause_category": {
      "faqs": [
        {
          question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
          answer: 'Dit is informatie die we met jouw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
        },
        {
          question: 'Wat betekenen de verschillende oorzaken precies?',
          answer: (<React.Fragment>
            <dl>
              <dt>Aanrijding met voertuig - </dt>
              <dd>Je hebt een aanrijding gehad met een ander gemotoriseerd voertuig, dit kan bijvoorbeeld een auto, busje, vrachtwagen of scooter zijn.</dd>
              <br />
              <br />
              <dt>Aanrijding met voetganger/fiets - </dt>
              <dd>Je hebt een aanrijding gehad met een niet-gemotoriseerd voertuig zoals een fiets of een voetganger.</dd>
              <br />
              <br />
              <dt>Aanrijding met dier - </dt>
              <dd>Je hebt een aanrijding gehad met een dier op de weg, bijvoorbeeld een hond, hert of gans.</dd>
              <br />
              <br />
              <dt>Aanrijding met (vast) object - </dt>
              <dd>Je hebt bijvoorbeeld een aanrijding gehad met een hekje, een vangrail of een bord langs de weg.</dd>{' '}
              <br />
              <br />
              <dt>Inbraak - </dt>
              <dd>Er is in jouw auto ingebroken en er zijn eigendommen van je uit de auto gestolen of er zijn onderdelen van jouw auto uit de auto gestolen.</dd>{' '}
              <br />
              <br />
              <dt>Vandalisme - </dt>
              <dd>Je auto is (opzettelijk) beschadigd aan binnen- of buitenkant.</dd>
              <br />
              <br />
              <dt>Hagel/storm - </dt>
              <dd>Je auto heeft schade opgelopen als gevolg van een hagelbui of een storm.</dd>
              <br />
              <br />
              <dt>Brand - </dt>
              <dd>Je auto heeft schade opgelopen als gevolg van een brand in of in de buurt van jouw auto.</dd>
            </dl>
          </React.Fragment>
          ),
        },
      ]
    },
    speed: {
      "title": 'Wat was je snelheid tijdens het incident?',
      "faqs": [
        {
          question: 'Waarom is het belangrijk om mijn snelheid te weten?',
          answer: 'Het is belangrijk voor het herstelbedrijf om te weten hoe hard je ongeveer reed tijdens het incident. Op deze manier kunnen zij de impact van jouw schade beter inschatten.'
        },
      ]
    },
    "interior": {
      "faqs": [
        {
          question: 'Wat wordt precies bedoeld met de binnenkant?',
          answer: 'Hiermee willen we weten of je auto door de impact van het incident ook van binnen beschadigd is. Bijvoorbeeld een verwrongen dorpel bij het openen van het portier. Wij bedoelen bijvoorbeeld niet een vlek op de bekleding.',
        },
      ]
    },
    exterior: {
      "title": 'Waar is jouw auto beschadigd?',
      "faqs": [
        {
          question: 'Wat als mijn auto op meerdere plekken beschadigd is?',
          answer: 'Je kunt meerdere vakken aangeven waar jouw auto beschadigd is.',
        },
        {
          question: 'Ik kan de plek waar mijn auto is beschadigd niet aangeven, wat moet ik nu doen?',
          answer: 'Arceer het vlak dat het meest in de buurt is van jouw schade. Je kunt dit later telefonisch toelichten wanneer het herstelbedrijf je zal bellen om een afspraak te maken voor herstel.'
        },
      ]
    },
    "glass_damage_sort": {
      "faqs": [{
        question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
        answer: 'Dit is informatie die we met jouw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
      }]
    },
    "uploads_start": {
      "title": "Bedankt voor het beschrijven van je schade.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    "images": {
      "title": "Foto's van jouw schade",
      "mobile-text": "Klik hier of op het vraagteken rechtsboven voor instructies.",
      "intro-text": "Wij hebben minimaal 5 foto’s van jouw schade nodig: van een afstandje, dichtbij, onder een schuine hoek en van het dashboard. Let op een goede belichting!",
      "max-img-size": 10,
      "max-number-images": 10,
      "instructions": [{ title: 'Overzichtsfoto', explanation: 'Ga 1 tot 1,5 meter van je auto staan. Maak een foto van de schade waarop de hele auto te zien is.' }, { title: 'Detailfoto vooraanzicht', explanation: 'Ga 0,5 tot 1 meter van je auto staan en recht voor de schade.' }, { title: 'Detailfoto hoek', explanation: 'Ga 0,5 tot 1 meter van de auto staan. Fotografeer de schade vanuit een hoek.' }, { title: 'Foto binnenkant auto', explanation: 'Als je bijvoorbeeld schade bij een van de portieren of aan de achterklep heeft, open deze en maak een foto van de binnenkant.' }, { title: 'Foto dashboard', explanation: 'Maak een foto van het dashboard als de motor draait (alle lampjes zijn aan). Het is belangrijk dat de kilometerstand goed zichtbaar is.' }],
      "faqs": [
        {
          question: 'Waarom hebben jullie foto’s van mijn schade nodig?',
          answer: 'Op basis van de foto’s kan het herstelbedrijf een inschatting maken hoe groot de schade is en hoe lang zij nodig hebben om deze te herstellen.'
        },
        {
          question: 'Wat doen jullie met de foto’s van mijn auto?',
          answer: 'Wij delen deze foto’s met het herstelbedrijf zodat zij de schade beter kunnen inschatten.',
        }]
    },
    "green_card": {
      "title": "Foto van je groene kaart",
      "intro-text": "Maak een foto of screenshot van je groene kaart en zorg dat de polisgegevens duidelijk leesbaar zijn.",
      "second-text": <React.Fragment><b>Let op:</b>: zonder polisgegevens kan het herstelbedrijf niet voor je aan de slag!</React.Fragment>,
      "faqs": [{
        question: 'Waar kan ik mijn polisgegevens vinden?',
        answer: 'Met het afsluiten van je verzekering heb je een ‘Groene kaart’ ontvangen. Ondanks de naam kan deze ook een andere kleur hebben. Afhankelijk van jouw verzekeraar heb je deze op papier of digitaal ontvangen.'
      },
      {
        question: 'Waarom moet ik mijn polisgegevens bijvoegen?',
        answer: 'Het herstelbedrijf heeft jouw polisnummer nodig om de juiste gegevens bij jouw verzekeraar op te halen. Zonder  bevestiging van de verzekeraar kan het herstelbedrijf niet starten met het herstellen van jouw schade.'
      },
      {
        question: 'Wat gebeurt er als ik mijn polisgegevens niet bijvoeg?',
        answer: 'Het herstelbedrijf zal je er later alsnog om moeten vragen. Om jouw tijd én dat van het herstelbedrijf niet te verspillen vragen we je om jouw polisgegevens nu al bij het dossier te voegen.'
      },
      ]
    },
    "licenseplate_register": {
      "title": "Foto van je kentekenbewijs",
      "intro-text": <React.Fragment>Upload een foto van de <b>achterkant</b> van je kentekenbewijs (vetgedrukt op het voorbeeld hieronder). Zorg dat het chassisnummer duidelijk leesbaar is.</React.Fragment>,
      "faqs": [{
        question: 'Wat is het chassisnummer?',
        answer: 'Het chassisnummer is een unique identificatie code die bij jouw auto hoort. Met deze code kan het herstelbedrijf de specificaties van jouw auto terug vinden en de onderdelen bestellen die horen bij jouw auto, model en type.'
      },
      {
        question: 'Waarom moet ik het chassisnummer bijvoegen?',
        answer: 'Met het chassisnummer kan het herstelbedrijf alvast onderdelen bestellen voor jouw auto zodat deze klaar liggen als het herstel begint. Hiermee heb je sneller jouw auto weer terug!'
      },
      ]
    },
    "recovery_start": {
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    success: {
      "feedback-timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    }
  }

  const intakeSpecial = {
    "personal_start": {
      "text": 'Hiernaast zie je de stappen om jouw schade volledig te melden.',
      "second-text": "Heb je alle informatie toegevoegd? Dan stellen wij het meest geschikte herstelbedrijf aan je voor.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    license: {
      "title": !inCreation ? 'Controleer je schadedatum' : 'Vul je kenteken en de schadedatum in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van jouw adres kunnen bepalen wat het beste herstelbedrijf in jouw regio is om je schade te herstellen. Zij zullen jouw auto ook bij je op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    name: {
      "title": !inCreation ? 'Controleer je naam' : 'Vul je naam in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van jouw adres kunnen bepalen wat het beste herstelbedrijf in jouw regio is om je schade te herstellen. Zij zullen jouw auto ook bij je op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    contact: {
      "title": isMobile ? 'Vul je email en telefoon-nummer in' : 'Vul je email en telefoonnummer in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn contactgegevens nodig?',
          answer: 'Als we jouw telefoonnummer hebben, kunnen wij dit delen met het herstelbedrijf dat jouw auto zal herstellen. Zij zullen je bellen om een afspraak te maken wanneer ze jouw auto kunnen ophalen. Met jouw e-mailadres kunnen we je op de hoogte houden van de voortgang van het herstel van jouw auto.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    postal: {
      "title": !inCreation ? 'Controleer je postcode en huisnummer' : 'Vul je postcode en huisnummer',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van jouw adres kunnen bepalen wat het beste herstelbedrijf in jouw regio is om je schade te herstellen. Zij zullen jouw auto ook bij je op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    address: {
      "title": 'Controleer je adres',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van jouw adres kunnen bepalen wat het beste herstelbedrijf in jouw regio is om je schade te herstellen. Zij zullen jouw auto ook bij je op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'Je hebt jouw schade gemeld. Deze geegvens waren al bekend bij Stern',
        }]
    },
    gender: {
      "title": 'Hoe wil je aangesproken worden',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "damage_start": {
      "title": "Bedankt voor je persoonlijke gegevens",
      "text": "Klik op “Volgende” om je schade in een paar stappen te beschrijven.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    "damage_cause_category": {
      "faqs": [
        {
          question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
          answer: 'Dit is informatie die we met jouw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
        },
        {
          question: 'Wat betekenen de verschillende oorzaken precies?',
          answer: (<React.Fragment>
            <dl>
              <dt>Aanrijding met voertuig - </dt>
              <dd>Je hebt een aanrijding gehad met een ander gemotoriseerd voertuig, dit kan bijvoorbeeld een auto, busje, vrachtwagen of scooter zijn.</dd>
              <br />
              <br />
              <dt>Aanrijding met voetganger/fiets - </dt>
              <dd>Je hebt een aanrijding gehad met een niet-gemotoriseerd voertuig zoals een fiets of een voetganger.</dd>
              <br />
              <br />
              <dt>Aanrijding met dier - </dt>
              <dd>Je hebt een aanrijding gehad met een dier op de weg, bijvoorbeeld een hond, hert of gans.</dd>
              <br />
              <br />
              <dt>Aanrijding met (vast) object - </dt>
              <dd>Je hebt bijvoorbeeld een aanrijding gehad met een hekje, een vangrail of een bord langs de weg.</dd>{' '}
              <br />
              <br />
              <dt>Inbraak - </dt>
              <dd>Er is in jouw auto ingebroken en er zijn eigendommen van je uit de auto gestolen of er zijn onderdelen van jouw auto uit de auto gestolen.</dd>{' '}
              <br />
              <br />
              <dt>Vandalisme - </dt>
              <dd>Je auto is (opzettelijk) beschadigd aan binnen- of buitenkant.</dd>
              <br />
              <br />
              <dt>Hagel/storm - </dt>
              <dd>Je auto heeft schade opgelopen als gevolg van een hagelbui of een storm.</dd>
              <br />
              <br />
              <dt>Brand - </dt>
              <dd>Je auto heeft schade opgelopen als gevolg van een brand in of in de buurt van jouw auto.</dd>
            </dl>
          </React.Fragment>
          ),
        },
      ]
    },
    speed: {
      "title": 'Wat was je snelheid tijdens het incident?',
      "faqs": [
        {
          question: 'Waarom is het belangrijk om mijn snelheid te weten?',
          answer: 'Het is belangrijk voor het herstelbedrijf om te weten hoe hard je ongeveer reed tijdens het incident. Op deze manier kunnen zij de impact van jouw schade beter inschatten.'
        },
      ]
    },
    "interior": {
      "faqs": [
        {
          question: 'Wat wordt precies bedoeld met de binnenkant?',
          answer: 'Hiermee willen we weten of je auto door de impact van het incident ook van binnen beschadigd is. Bijvoorbeeld een verwrongen dorpel bij het openen van het portier. Wij bedoelen bijvoorbeeld niet een vlek op de bekleding.',
        },
      ]
    },
    exterior: {
      "title": 'Waar is jouw auto beschadigd?',
      "faqs": [
        {
          question: 'Wat als mijn auto op meerdere plekken beschadigd is?',
          answer: 'Je kunt meerdere vakken aangeven waar jouw auto beschadigd is.',
        },
        {
          question: 'Ik kan de plek waar mijn auto is beschadigd niet aangeven, wat moet ik nu doen?',
          answer: 'Arceer het vlak dat het meest in de buurt is van jouw schade. Je kunt dit later telefonisch toelichten wanneer het herstelbedrijf je zal bellen om een afspraak te maken voor herstel.'
        },
      ]
    },
    "glass_damage_sort": {
      "faqs": [{
        question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
        answer: 'Dit is informatie die we met jouw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
      }]
    },
    "uploads_start": {
      "title": "Bedankt voor het beschrijven van je schade.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    "images": {
      "title": "Foto's van jouw schade",
      "mobile-text": "Klik hier of op het vraagteken rechtsboven voor instructies.",
      "intro-text": "Wij hebben minimaal 5 foto’s van jouw schade nodig: van een afstandje, dichtbij, onder een schuine hoek en van het dashboard. Let op een goede belichting!",
      "max-img-size": 10,
      "max-number-images": 10,
      "instructions": [{ title: 'Overzichtsfoto', explanation: 'Ga 1 tot 1,5 meter van je auto staan. Maak een foto van de schade waarop de hele auto te zien is.' }, { title: 'Detailfoto vooraanzicht', explanation: 'Ga 0,5 tot 1 meter van je auto staan en recht voor de schade.' }, { title: 'Detailfoto hoek', explanation: 'Ga 0,5 tot 1 meter van de auto staan. Fotografeer de schade vanuit een hoek.' }, { title: 'Foto binnenkant auto', explanation: 'Als je bijvoorbeeld schade bij een van de portieren of aan de achterklep heeft, open deze en maak een foto van de binnenkant.' }, { title: 'Foto dashboard', explanation: 'Maak een foto van het dashboard als de motor draait (alle lampjes zijn aan). Het is belangrijk dat de kilometerstand goed zichtbaar is.' }],
      "faqs": [
        {
          question: 'Waarom hebben jullie foto’s van mijn schade nodig?',
          answer: 'Op basis van de foto’s kan het herstelbedrijf een inschatting maken hoe groot de schade is en hoe lang zij nodig hebben om deze te herstellen.'
        },
        {
          question: 'Wat doen jullie met de foto’s van mijn auto?',
          answer: 'Wij delen deze foto’s met het herstelbedrijf zodat zij de schade beter kunnen inschatten.',
        }]
    },
    "green_card": {
      "title": "Foto van je groene kaart",
      "intro-text": "Maak een foto of screenshot van je groene kaart en zorg dat de polisgegevens duidelijk leesbaar zijn.",
      "second-text": <React.Fragment><b>Let op:</b>: zonder polisgegevens kan het herstelbedrijf niet voor je aan de slag!</React.Fragment>,
      "faqs": [{
        question: 'Waar kan ik mijn polisgegevens vinden?',
        answer: 'Met het afsluiten van je verzekering heb je een ‘Groene kaart’ ontvangen. Ondanks de naam kan deze ook een andere kleur hebben. Afhankelijk van jouw verzekeraar heb je deze op papier of digitaal ontvangen.'
      },
      {
        question: 'Waarom moet ik mijn polisgegevens bijvoegen?',
        answer: 'Het herstelbedrijf heeft jouw polisnummer nodig om de juiste gegevens bij jouw verzekeraar op te halen. Zonder  bevestiging van de verzekeraar kan het herstelbedrijf niet starten met het herstellen van jouw schade.'
      },
      {
        question: 'Wat gebeurt er als ik mijn polisgegevens niet bijvoeg?',
        answer: 'Het herstelbedrijf zal je er later alsnog om moeten vragen. Om jouw tijd én dat van het herstelbedrijf niet te verspillen vragen we je om jouw polisgegevens nu al bij het dossier te voegen.'
      },
      ]
    },
    "licenseplate_register": {
      "title": "Foto van je kentekenbewijs",
      "intro-text": <React.Fragment>Upload een foto van de <b>achterkant</b> van je kentekenbewijs (vetgedrukt op het voorbeeld hieronder). Zorg dat het chassisnummer duidelijk leesbaar is.</React.Fragment>,
      "faqs": [{
        question: 'Wat is het chassisnummer?',
        answer: 'Het chassisnummer is een unique identificatie code die bij jouw auto hoort. Met deze code kan het herstelbedrijf de specificaties van jouw auto terug vinden en de onderdelen bestellen die horen bij jouw auto, model en type.'
      },
      {
        question: 'Waarom moet ik het chassisnummer bijvoegen?',
        answer: 'Met het chassisnummer kan het herstelbedrijf alvast onderdelen bestellen voor jouw auto zodat deze klaar liggen als het herstel begint. Hiermee heb je sneller jouw auto weer terug!'
      },
      ]
    },
    "recovery_start": {
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    success: {
      "feedback-timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    }
  }

  const intakePlus = {
    "personal_start": {
      "show-timeline": true,
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }]
    },
    "damage_start": {
      "show-timeline": true,
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }]
    },
    "uploads_start": {
      "show-timeline": true,
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }]
    },
    "images": {
      "instructions": [{ title: 'Overzichtsfoto', explanation: 'Ga 1 tot 1,5 meter van uw auto staan. Maak een foto van de schade waarop uw hele auto te zien is.' }, { title: 'Detailfoto vooraanzicht', explanation: 'Ga 0,5 tot 1 meter van uw auto staan en recht voor de schade.' }, { title: 'Detailfoto hoek', explanation: 'Ga 0,5 tot 1 meter van uw auto staan. Fotografeer de schade vanuit een hoek.' }, { title: 'Foto binnenkant auto', explanation: 'Als u bijvoorbeeld schade bij een van de portieren of aan de achterklep heeft, open deze en maak een foto van de binnenkant.' }, { title: 'Foto dashboard', explanation: 'Maak een foto van het dashboard als de motor draait (het contact is aan). Het is belangrijk dat de kilometerstand goed zichtbaar is.' }],
    },
    "created_by_dealer": {
      "title": 'Heeft u met de geselecteerde dealer al een afspraak gemaakt voor herstel?',
    },
    "postal": {
      "button-text": "Volgende",
    },
    "damage_cause_dropdown": {
      "button-text": "Volgende",
    },
    "vehicle_mileage": {
      "title": "Wat is de kilometerstand?",
    },
    "ownbodyshop_start": {
      "show-timeline": true,
      "timeline-title": null,
      "title": "Bedankt voor het toevoegen van uw foto’s",
      "title-no-image": "Klik op ‘Volgende’ om een herstelbedrijf te kiezen.",
      "text": 'Klik op ‘Volgende’ om een herstelbedrijf te kiezen.',
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }]
    },
    "bodyshop_confirm": {
      "text": null
    },
    "recovery_start": {
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Persoonlijke gegevens" }, { icon: "questionmark", text: "Schade beschrijven" }, { icon: "file", text: "Foto’s toevoegen" }, { icon: "star", text: "Herstelbedrijf kiezen" }]
    },
    "images": {
      "mobile-text": <React.Fragment>Klik <u><b>hier</b></u> of op het vraagteken rechtsboven voor instructies.</React.Fragment>,
    },
    "extra_damage": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Heeft u nog andere schades die u wilt laten herstellen?',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    end: {
      "use-custom-logo": false,
      "show-left-logo": false,
      "company-middle-logo": true,
      "title": incident ? `Beste ${incident.customerFirstName} ${incident.customerLastName}` : "Beste klant",
      "show-bodyshop-block": false,
      "feedback-text": "Bedankt. We hebben de fotos in goede orde ontvangen en zullen binnen 1 werkdag contact met je opnemen.",
      "text": `${isMobile ? 'Hieronder' : 'Hiernaast'} vindt je een overzicht van de vervolgstappen om het gehele proces snel en professioneel af te ronden.`,
      "second-text": null,
      "third-text": null,
      "end-text": "U kunt deze pagina sluiten.",
      "show-timeline": true,
      "timeline-title": null,
      "timeline-show-check": false,
      "timeline": [{ icon: "user", text: "Uw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
      "feedback-timeline": [{ icon: "user", text: "Uw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
      "failed-timeline": null,
      "spcase-failed-timeline": null,
      "show-background": true,
    },
    success: {
      "feedback-text": "Bedankt. We hebben de extra informatie in goede orde ontvangen en zullen binnen 1 werkdag contact met u opnemen.",
      "failed-text": `Helaas bieden kunnen wij op dit moment  geen hersteller voor u vinden. U kunt daarom geen gebruik van deze dienst. Neem contact op met uw adviseur voor het afhandelen van uw schade.`,
      "end-text": "U kunt deze pagina sluiten.",
      "timeline": [{ icon: "user", text: "Uw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
      "feedback-timeline": [{ icon: "mail", text: "Schade beschrijven." }, { icon: "user", text: `U wordt zo spoedig mogelijk gebeld door ${bodyshop ? bodyshop.name || bodyshop.companyName : "uw dealer"}`, active: true }, { icon: "towtruck", text: `Uw auto gaat naar ${bodyshop ? bodyshop.name || bodyshop.companyName : "uw dealer"}` }, { icon: "wrench", text: `${bodyshop ? bodyshop.name || bodyshop.companyName : "Uw dealer"} herstelt uw auto.` }, { icon: "car-returned", text: "Uw auto is hersteld" }],
    },
  }

  const dealerDistribution = {
    "personal_start": {
      "text": 'Hiernaast zie je alle stappen voor het melden van je schade.',
      "second-text": "Heb je alle informatie toegevoegd? Dan stellen wij het meest geschikte herstelbedrijf aan je voor.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    license: {
      "title": !inCreation ? 'Controleer je schadedatum' : 'Vul je kenteken en de schadedatum in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Aan de hand van jouw adres bepalen wij welk herstelbedrijf in jouw regio het meest geschikt is om jouw schade te herstellen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: `Je hebt jouw schade gemeld. Deze gegevens waren al bekend bij ${insurer.brandName}`,
        }]
    },
    name: {
      "title": !inCreation ? 'Controleer je naam' : 'Vul je naam in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Aan de hand van jouw adres bepalen wij welk herstelbedrijf in jouw regio het meest geschikt is om jouw schade te herstellen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: `Je hebt jouw schade gemeld. Deze gegevens waren al bekend bij ${insurer.brandName}`,
        }
      ]
    },
    contact: {
      "title": isMobile ? 'Vul je email en telefoon-nummer in' : 'Vul je email en telefoonnummer in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Aan de hand van jouw adres bepalen wij welk herstelbedrijf in jouw regio het meest geschikt is om jouw schade te herstellen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: `Je hebt jouw schade gemeld. Deze gegevens waren al bekend bij ${insurer.brandName}`,
        }]
    },
    postal: {
      "title": 'Vul je postcode en huisnummer',
      "faqs": [{
        question: 'Waarom hebben jullie mijn adres nodig?',
        answer: 'Aan de hand van jouw adres bepalen wij welk herstelbedrijf in jouw regio het meest geschikt is om jouw schade te herstellen.'
      },
      {
        question: 'Met wie worden mijn gegevens gedeeld?',
        answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
      },
      {
        question: 'Hoe komen jullie aan mijn gegevens?',
        answer: `Je hebt jouw schade gemeld. Deze gegevens waren al bekend bij ${insurer.brandName}`
      }]
    },
    address: {
      "title": 'Controleer je adres',
      "faqs": [{
        question: 'Waarom hebben jullie mijn adres nodig?',
        answer: 'Aan de hand van jouw adres bepalen wij welk herstelbedrijf in jouw regio het meest geschikt is om jouw schade te herstellen.'
      },
      {
        question: 'Met wie worden mijn gegevens gedeeld?',
        answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
      },
      {
        question: 'Hoe komen jullie aan mijn gegevens?',
        answer: `Je hebt jouw schade gemeld. Deze gegevens waren al bekend bij ${insurer.brandName}`
      }]
    },
    gender_2: {
      "title": 'Hoe mogen we je aanspreken?',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Je gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van jouw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "damage_start": {
      "title": "Bedankt voor je persoonlijke gegevens",
      "text": "Klik op “Volgende” om je schade in een paar stappen te beschrijven.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    "damage_cause_category": {
      "faqs": [
        {
          question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
          answer: 'Dit is informatie die we met jouw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in verschillende groepen.'
        },
        {
          question: 'Wat betekenen de verschillende oorzaken precies?',
          answer: (<React.Fragment>
            <dl>
              <dt>Aanrijding met voertuig - </dt>
              <dd>- Je hebt een aanrijding gehad met een ander gemotoriseerd voertuig, dit kan bijvoorbeeld een auto, busje, vrachtwagen of scooter zijn.</dd>
              <br />
              <br />
              <dt>Aanrijding met voetganger/fiets - </dt>
              <dd>Je hebt een aanrijding gehad met een niet-gemotoriseerd voertuig zoals een fiets of een voetganger.</dd>
              <br />
              <br />
              <dt>Aanrijding met dier - </dt>
              <dd>Je hebt een aanrijding gehad met een dier op de weg, bijvoorbeeld een hond, hert of gans.</dd>
              <br />
              <br />
              <dt>Aanrijding met (vast) object - </dt>
              <dd>Je hebt bijvoorbeeld een aanrijding gehad met een hekje, een vangrail of een bord langs de weg.</dd>{' '}
              <br />
              <br />
              <dt>Inbraak - </dt>
              <dd>Er is in jouw auto ingebroken en er zijn eigendommen van je uit de auto gestolen of er zijn onderdelen van jouw auto uit de auto gestolen.</dd>{' '}
              <br />
              <br />
              <dt>Vandalisme - </dt>
              <dd>Je auto is (opzettelijk) beschadigd aan binnen- of buitenkant.</dd>
              <br />
              <br />
              <dt>Hagel/storm - </dt>
              <dd>Je auto heeft schade opgelopen als gevolg van een hagelbui of een storm.</dd>
              <br />
              <br />
              <dt>Brand - </dt>
              <dd>Je auto heeft schade opgelopen als gevolg van een brand in of in de buurt van jouw auto.</dd>
            </dl>
          </React.Fragment>
          ),
        },
      ]
    },
    speed: {
      "title": 'Wat was je snelheid tijdens het incident?',
      "faqs": [
        {
          question: 'Waarom is het belangrijk om mijn rijsnelheid te weten?',
          answer: 'Het is belangrijk voor het herstelbedrijf om te weten hoe hard je ongeveer reed tijdens het incident. Op deze manier kunnen zij de impact van jouw schade beter inschatten.'
        },
      ]
    },
    "interior": {
      "title": 'Is de auto aan de binnenkant beschadigd?',
      "faqs": [
        {
          question: 'Wat wordt precies bedoeld met de binnenkant?',
          answer: 'We willen weten of je auto door de impact van het incident ook van binnen beschadigd is. Bijvoorbeeld een verwrongen dorpel bij het openen van het portier. Wij bedoelen bijvoorbeeld niet een vlek op de bekleding.',
        },
      ]
    },
    exterior: {
      "title": 'Waar is jouw auto beschadigd?',
      "faqs": [
        {
          question: 'Wat als mijn auto op meerdere plekken beschadigd is?',
          answer: 'Je kunt meerdere vakken aangeven waar jouw auto beschadigd is.',
        },
        {
          question: 'Ik kan de plek waar mijn auto is beschadigd niet aangeven, wat moet ik nu doen?',
          answer: 'Arceer het vlak dat het meest in de buurt is van jouw schade. Je kunt dit later telefonisch toelichten wanneer het herstelbedrijf je belt om een afspraak te maken voor herstel.'
        },
      ]
    },
    "uploads_start": {
      "title": "Bedankt voor het beschrijven van de schade.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    "images": {
      "title": "Foto's van de schade",
      "mobile-text": "Klik hier of op het vraagteken rechtsboven voor instructies.",
      "intro-text": "We hebben minimaal 5 foto’s nodig voor een goede beoordeling; van afstand, van dichtbij, onder een schuine hoek en van het dashboard. De instructies voor het maken van de foto vindt u hieronder. Let op een goede belichting!",
      "max-img-size": 10,
      "max-number-images": 30,
      "instructions": [{
        title: 'Overzichtsfoto',
        explanation: 'Ga 1 tot 1,5 meter van de auto staan. Maak een foto van de schade waarop de hele auto te zien is.'
      }, {
        title: 'Detailfoto vooraanzicht',
        explanation: 'Ga 0,5 tot 1 meter van de auto staan en recht voor de schade.'
      }, {
        title: 'Detailfoto hoek',
        explanation: 'Ga 0,5 tot 1 meter van de auto staan. Fotografeer de schade vanuit een hoek.'
      }, {
        title: 'Foto binnenkant auto',
        explanation: 'Als je bijvoorbeeld schade bij een van de portieren of aan de achterklep hebt, open deze en maak een foto van de binnenkant.'
      }, {
        title: 'Foto dashboard',
        explanation: 'Maak een foto van het dashboard als de motor draait (alle lampjes zijn aan). Het is belangrijk dat de kilometerstand goed zichtbaar is.'
      }],
      "faqs": [
        {
          question: 'Waarom hebben jullie foto’s van mijn schade nodig?',
          answer: 'Op basis van de foto’s kan het herstelbedrijf inschatten hoe groot de schade is en hoe lang zij nodig hebben om deze te herstellen.'
        },
        {
          question: 'Wat doen jullie met de foto’s van mijn auto?',
          answer: 'Wij delen deze foto’s met het herstelbedrijf, zodat zij de schade beter kunnen inschatten.',
        }],
      "validation-text": 'Voeg alsjeblieft minimaal vijf foto’s toe'
    },
    saf: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Schadeformulier",
      "intro-text": "Heeft u een ingevuld schadeformulier? Voeg deze dan toe.",
      "second-text": "Klik op “Volgende” als u geen schadeformulier wilt toevoegen.",
      "intro-text-required": "Upload hier uw schadeformulier.",
      "second-text-required": "Klik op volgende zodra het schadeformulier is geüpload.",
      "faqs": [
        {
          question: 'Waarom zijn er aanvullende documenten nodig?',
          answer: 'Door documenten - zoals een ingevuld schadeaangifteformulier - te uploaden kunnen we het schadevoorval sneller en beter beoordelen.',

        },
        {
          question: 'Moet het document een bepaald bestandstype zijn?',
          answer: 'Alle veelgebruikte documenttypes zijn toegestaan.',
        }
      ],
      "validation-text": 'Voeg alsjeblieft minimaal een bestand toe'
    },
    "green_card": {
      "title": "Foto van je groene kaart",
      "intro-text": "Maak een foto of screenshot van je groene kaart en zorg dat de polisgegevens duidelijk leesbaar zijn.",
      "second-text": <React.Fragment><b>Let op:</b>: zonder polisgegevens kan het herstelbedrijf niet voor je aan de slag!</React.Fragment>,
      "faqs": [{
        question: 'Waar kan ik mijn polisgegevens vinden?',
        answer: 'Met het afsluiten van je verzekering heb je een ‘Groene kaart’ ontvangen. Ondanks de naam kan deze ook een andere kleur hebben. Afhankelijk van jouw verzekeraar heb je deze op papier of digitaal ontvangen.'
      },
      {
        question: 'Waarom moet ik mijn polisgegevens bijvoegen?',
        answer: 'Het herstelbedrijf heeft jouw polisnummer nodig om de juiste gegevens bij jouw verzekeraar op te halen. Zonder  bevestiging van de verzekeraar kan het herstelbedrijf niet starten met het herstellen van jouw schade.'
      },
      {
        question: 'Wat gebeurt er als ik mijn polisgegevens niet bijvoeg?',
        answer: 'Het herstelbedrijf zal je er later alsnog om moeten vragen. Om jouw tijd én dat van het herstelbedrijf niet te verspillen vragen we je om jouw polisgegevens nu al bij het dossier te voegen.'
      },
      ],
      "validation-text": 'Voeg alsjeblieft minimaal een bestand toe'
    },
    "licenseplate_register": {
      "title": "Foto van je kentekenbewijs",
      "intro-text": <React.Fragment>Upload een foto van de <b>achterkant</b> van je kentekenbewijs (vetgedrukt op het voorbeeld hieronder). Zorg dat het chassisnummer duidelijk leesbaar is.</React.Fragment>,
      "faqs": [{
        question: 'Wat is het chassisnummer?',
        answer: 'Het chassisnummer is een unique identificatie code die bij jouw auto hoort. Met deze code kan het herstelbedrijf de specificaties van jouw auto terug vinden en de onderdelen bestellen die horen bij jouw auto, model en type.'
      },
      {
        question: 'Waarom moet ik het chassisnummer bijvoegen?',
        answer: 'Met het chassisnummer kan het herstelbedrijf alvast onderdelen bestellen voor jouw auto, zodat deze klaar liggen   als ze met de auto aan de slag gaan. Hiermee heb je jouw auto sneller weer terug!'
      },
      ],
      "validation-text": 'Voeg alsjeblieft minimaal een bestand toe'
    },
    "recovery_start": {
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    },
    end: {
      "text": `Je hebt alle gegevens ingevuld en wij gaan voor je aan de slag. Je ontvangt hiervan een bevestiging in je mailbox.`,
      "end-text": "Je kunt deze pagina nu afsluiten.",
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
      "feedback-timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
    }
  }
  const notCustom = {}
  const contentOptions = { dealerDistribution, intakePlus, rhionCasco, VIP, EM, DI, schadegarant, dealerSaas, intake, intakeSpecial, notCustom }
  return contentOptions[contentType]

}

export default customContent